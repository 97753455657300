<template lang="pug">
v-card(v-if="Object.keys(statement).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div.text-left.text-uppercase {{ $t(`statement-nostrification-${Object.keys(statement?.behavior)[0]}`, { id: statement?.id }) }}
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    Info(v-if="statement?.behavior?.viewInfoBlock" :statement="statement")
    ViewPhotoList(
      v-else-if="statement?.behavior?.viewFilesBlock"
      :sailorDocument="statement"
      :isHiddenActionsButton="!(statement.nostrification_document_status === STATUSES_STATEMENT_CONSTANTS.DRAFT_122)"
      documentType="nostrification")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
export default {
  components: {
    Info: () => import('./Info.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      controlButtons: [
        {
          id: 1,
          name: 'mdi-account-arrow-right-outline',
          action: () => {
            const routerData = this.$router.resolve({
              name: 'sailor',
              params: { id: this.statement.sailor.id }
            })
            window.open(routerData.href)
          },
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.goToSailor',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          checkAccess: () => checkAccess('nostrification', 'view'),
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 6,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.statement, 'viewFilesBlock'),
          checkAccess: () => checkAccess('nostrification', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.viewfiles'
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'nostrification', query: { ...this.$route.query } }),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      statement: state => state.statement.nostrificationById
    })
  },
  beforeMount () {
    this.$store.commit('setActivePage', { name: 'nostrification', access: checkAccess('menuItem-nostrification') })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getStatementNostrificationById', 'updateStatementNostrificationById']),
    async getData () {
      const { id } = this.$route.params
      await this.getStatementNostrificationById({ id })
    }
  }
}
</script>
